import React from 'react'

const Preview = props => {
  console.log(props.content)
  // TODO: mark nsfw posts
  return (
    <a href={props.content.url} className='preview'>
      <h3 className='preview__title'>{props.content.title}</h3>
      {/*      <img
        src={
          props.content.preview
            ? props.content.preview.images[0].source.url
            : ''
        }
      />*/}

      <span className='preview__subreddit'>{props.content.subreddit}</span>
    </a>
  )
}

export default Preview
