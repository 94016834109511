import React from 'react'
import Preview from './Preview.js'

const Previews = props => {
  console.log('previews', props.previews)
  if (props.previews.length > 0) {
    return (
      <div className='preview-list'>
        {props.previews.map((content, index) => {
          return <Preview key={index} content={content} />
        })}
      </div>
    )
  } else {
    return <div>no posts</div>
  }
}

export default Previews
