import React, { useEffect, useState } from 'react'
import Previews from './components/Previews.js'

function App() {
  const [previews, setPreviews] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      let response = await fetch('https://www.reddit.com/r/all.json').then(
        data => data.json()
      )

      setPreviews(response.data.children.map(x => x.data))
    }
    fetchData()
  }, [])

  return (
    <div className='App'>
      <Previews previews={previews} />
    </div>
  )
}

export default App
